import React from "react";
import { ButtonTheme } from '../../components/buttons';
import { CardTheme } from '../../components/card/CardTheme';
import { TextLogoLogin } from '../../components/forms/text';
import RenderForm from '../../constants/global/RenderForm';
import { ForgetPasswordAdminForm } from '../../constants/global/SearchForm';
import { ContainerButton } from '../../style/styledComponentGlobal';
import { useForm } from 'react-hook-form'
import { useState } from 'preact/hooks'
import { FORGET_PASSWORD, POST } from '../../services'
import { Modal } from 'antd';
import { route } from 'preact-router';

const ForgetPasswordScene = () => {
	const { handleSubmit, errors, setValue, getValues, control } = useForm();
	const [loading, setLoading] = useState(false)

	const onSubmit = async (data) => {
		try {
			setLoading(true)
			const res = await POST(FORGET_PASSWORD, { ...data, role: 'admin' })
			const { result, success } = res
			if (success) {
				setLoading(false)
				Modal.success({
					title: result,
				});
				route('/admin/login')
			}
		} catch (err) {
			//alert
			setLoading(false)
			Modal.error({
				title: err.message,
			});
		}
	}

	return (
		<div style={{ backgroundImage: "url('../../assets/images/background.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
			<div style={{ display: 'flex', minHeight: '100vh', margin: '0 5%' }}>
				<div style={{ margin: 'auto' }}>
					<CardTheme
						// hoverable
						style={{ zIndex: '2' }}
						title={<TextLogoLogin text={'รีเซ็ตรหัสผ่าน [ADMIN]'} align={'center'} />}
						content={
							<form onSubmit={handleSubmit(onSubmit)}>
								<RenderForm
									control={control}
									setValue={setValue}
									getValues={getValues}
									errors={errors}
									formList={ForgetPasswordAdminForm({})}
									renderButton={
										<div style={{ display: 'flex', width: '100%' }}>
											<ContainerButton right>
												<ButtonTheme useFor="REQ_PASSWORD" title="ขอรหัสผ่านใหม่" htmlType="submit" loading={loading} />
											</ContainerButton>
										</div>
									}
								/>
							</form>
						}
					/>
				</div>

			</div>
		</div>
	)

}

export default ForgetPasswordScene
